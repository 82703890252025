.switch-container {
    font-family: "Lucida Grande", Tahoma, Verdana, sans-serif;
    position: relative;
    height: max-content;
    width: 100%;
    background-color: #e4e4e4;
    border-radius: 3px;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3), 0 1px rgba(255, 255, 255, 0.1);
    display: flex;
    margin-block: auto;
  
    span {
      width: 100%;
      display: flex;
      align-items: center;
    }

    &.disabled {
        pointer-events: none;

        .switch-label {
            color: rgba(0, 0, 0, 0.6) !important;
            background: transparent;
        }
    }
}

.switch-radio {
    display: none;
}


.switch-label {
    position: relative;
    padding: 6px 3px;
    z-index: 2;
    float: left;
    width: 100%;
    line-height: 26px;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);
    text-align: center;
    cursor: pointer;
    margin-bottom: 0;
    user-select: none;
    transition: .1s ease;

    &.selected {
        color: white;
        background: #216BA5;
    }
}


[data-layout-mode="dark"] {

    .switch-container {

        span {
            background: rgb(56, 55, 55);
        }
      
        &.disabled {
            filter: none;

            .switch-label {
                color: white !important;
    
                &.selected {
                    color: white;
                }
            }
        }

        .switch-label {
            color: white;
        }
    }

 
}