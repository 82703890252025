.selectable-tabs-container {

    display: flex;
    flex-wrap: wrap;
    gap: .3rem;

    .selectable-tab {
        position: relative;
        padding: .6rem 1rem;
        border: 1px solid rgb(200, 200, 200);
        background-color: var(--#{$variable-prefix}card-bg);
        border-radius: .3rem;
        user-select: none;
        gap: .2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: .4rem;
        cursor: pointer;

        & > img {
            height: 20px; 
        }

        &:not(.selected-tab):hover {
            filter: brightness(95%);
        }

        &.selected-tab {
            background-color: var(--#{$variable-prefix}success);
            color: white;

            &.heading-tab {
                background-color: var(--#{$variable-prefix}secondary);
            }

            &.option-tab {
                background-color: var(--#{$variable-prefix}primary);
            }

            &.allergy-tab, &.spices-tab {
                background-color: var(--#{$variable-prefix}danger);
            }

            &.doneness-tab {
                background-color: var(--#{$variable-prefix}warning);
            }

        }

        &.indicator::before {
            content: '';
            display: block;
            height: .4rem;
            width: .4rem;
            margin-block: .3rem;
            border-radius: 50%;
            background-color: limegreen;
        }

        &.disabled {
            pointer-events: none;
            cursor: none;
        }
    }
}

[data-layout-mode="dark"] {
    
    .selectable-tabs-container {
        .selectable-tab {
            border: 1px solid rgb(82, 82, 82);

            &:not(.selected-tab):hover {
                filter: brightness(150%);
            }
        }
    }
}