.thin-scrollbar {
    height: 100%;
    overflow: auto;

    &::-webkit-scrollbar {
        -webkit-appearance: none;
    }

    &::-webkit-scrollbar:vertical {
        width: 8px;
    }

    &::-webkit-scrollbar:horizontal {
        height: 10px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: rgba(var(--#{$prefix}dark-rgb), .09);
        border-radius: 8px;
    }

    &::-webkit-scrollbar-track {
        border-radius: 8px;
    }
}