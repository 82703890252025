html[dir="rtl"] {
    
    .fc-toolbar-chunk{
        .btn-group {
            >.btn:not(:last-child):not(.dropdown-toggle) {
                border-radius: 0px;
                border-top-left-radius: $btn-border-radius;
                border-bottom-left-radius: $btn-border-radius;
            }
            > :not(.btn-check)+.btn {
                border-radius: 0px;
                border-top-right-radius: $btn-border-radius;
                border-bottom-right-radius: $btn-border-radius;
            }
        }
    }

    [data-simplebar] {
        position: relative;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        -ms-flex-line-pack: start;
        align-content: flex-start;
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start
    }

    .simplebar-wrapper {
        overflow: hidden;
        width: inherit;
        height: inherit;
        max-width: inherit;
        max-height: inherit
    }

    .simplebar-mask {
        direction: inherit;
        position: absolute;
        overflow: hidden;
        padding: 0;
        margin: 0;
        right: 0;
        top: 0;
        bottom: 0;
        left: 0;
        width: auto !important;
        height: auto !important;
        z-index: 0
    }

    .simplebar-offset {
        direction: inherit !important;
        -webkit-box-sizing: inherit !important;
        box-sizing: inherit !important;
        resize: none !important;
        position: absolute;
        top: 0;
        right: 0 !important;
        bottom: 0;
        left: 0 !important;
        padding: 0;
        margin: 0;
        -webkit-overflow-scrolling: touch
    }

    .simplebar-content-wrapper {
        direction: inherit;
        -webkit-box-sizing: border-box !important;
        box-sizing: border-box !important;
        position: relative;
        display: block;
        height: 100%;
        width: auto;
        visibility: visible;
        overflow: auto;
        max-width: 100%;
        max-height: 100%;
        scrollbar-width: none;
        padding: 0 !important
    }

    .simplebar-content-wrapper::-webkit-scrollbar,
    .simplebar-hide-scrollbar::-webkit-scrollbar {
        display: none
    }

    .simplebar-content:after,
    .simplebar-content:before {
        content: " ";
        display: table
    }

    .simplebar-placeholder {
        max-height: 100%;
        max-width: 100%;
        width: 100%;
        pointer-events: none
    }

    .simplebar-height-auto-observer-wrapper {
        -webkit-box-sizing: inherit !important;
        box-sizing: inherit !important;
        height: 100%;
        width: 100%;
        max-width: 1px;
        position: relative;
        float: right;
        max-height: 1px;
        overflow: hidden;
        z-index: -1;
        padding: 0;
        margin: 0;
        pointer-events: none;
        -webkit-box-flex: inherit;
        -ms-flex-positive: inherit;
        flex-grow: inherit;
        -ms-flex-negative: 0;
        flex-shrink: 0;
        -ms-flex-preferred-size: 0;
        flex-basis: 0
    }

    .simplebar-height-auto-observer {
        -webkit-box-sizing: inherit;
        box-sizing: inherit;
        display: block;
        opacity: 0;
        position: absolute;
        top: 0;
        right: 0;
        height: 1000%;
        width: 1000%;
        min-height: 1px;
        min-width: 1px;
        overflow: hidden;
        pointer-events: none;
        z-index: -1
    }

    .simplebar-track {
        z-index: 1;
        position: absolute;
        left: 0;
        bottom: 0;
        pointer-events: none;
        overflow: hidden
    }

    [data-simplebar].simplebar-dragging .simplebar-content {
        pointer-events: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        -webkit-user-select: none
    }

    [data-simplebar].simplebar-dragging .simplebar-track {
        pointer-events: all
    }

    .simplebar-scrollbar {
        left: 2px;
        right: auto;
    }


    .simplebar-track.simplebar-horizontal {
        right: 0;
        left: auto;
        height: 11px
    }

    .simplebar-track.simplebar-horizontal .simplebar-scrollbar {
        left: auto;
        right: 0;
        top: 2px;
        height: 7px;
        min-height: 0;
        min-width: 10px;
        width: auto
    }

    [data-simplebar-direction=rtl] .simplebar-track.simplebar-vertical {
        left: auto;
        right: 0
    }

    .hs-dummy-scrollbar-size {
        direction: ltr;
    }

    .simplebar-hide-scrollbar {
        right: 0;
        left: auto;
    }

}

