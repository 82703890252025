.google-maps-marker-label {
    position: absolute;
    bottom: -13%;
    left: 50%;
    transform: translateX(-50%);
    background: white;
    color: rgb(94, 94, 94) !important;
    padding: .4rem .7rem;
    border-radius: .5rem;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

button.gm-ui-hover-effect {
    display: none !important;
}