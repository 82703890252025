.nestable-list {

    .nestable-item, .nestable-item-copy {
        background-color: rgba(61, 120, 227, 0.05);
        padding: 0.6rem 0.3rem;
        cursor: pointer;

        &.is-dragging {

            &::before {
                background-color: rgba(17, 38, 79, 0.1);
                border-radius: 0;
            }
        }

        .nestable-item-name {
            display: flex;
            align-items: center;
            gap: .5em;

            &::before {
                content: url("../../../assets/images/svg/icons/menu-line.svg");
                padding: 0.3rem;
                font-size: .5rem;
                cursor: pointer;
                align-self: center;
            }

            .item-title-container {
                display: flex;
                align-items: center;
                gap: 1rem;

                img {
                    width: 50px;
                    height: 50px;
                    border-radius: 50%;
                    object-fit: contain;
                }

                span {
                    font-size: .9rem;
                }
            }
        }
    }
}

.modal-nestable-list {
    padding-right: .8rem;
    max-height: 70vh;
    overflow-y: auto;

    &::-webkit-scrollbar {
        -webkit-appearance: none;
    }

    &::-webkit-scrollbar:vertical {
        width: 8px;
    }

    &::-webkit-scrollbar:horizontal {
        height: 10px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: rgba(var(--#{$prefix}dark-rgb), .09);
        border-radius: 8px;
    }

    &::-webkit-scrollbar-track {
        border-radius: 8px;
    }
    
}

[data-layout-mode="dark"] {
    .nestable-list {
        .nestable-item, .nestable-item-copy {
            .nestable-item-name {
                &::before {
                    filter: invert(100%);
                }
            }
        }
    }
}
