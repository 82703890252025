//
// _vertical.scss
// 

.app-content {
    margin-left: $vertical-menu-width;
    overflow: hidden;

    .content {
        padding: 0 15px 10px 15px;
        margin-top: $header-height;
    }
}

.main-content {
    transition: all .1s ease-out;

    @media (min-width: 768px) {
        margin-left: $vertical-menu-width;
    }
}

.page-content {
    padding: calc(#{$header-height} + #{$grid-gutter-width}) calc(#{$grid-gutter-width} * 0.5) $footer-height calc(#{$grid-gutter-width} * 0.5);
}

.navbar-menu {
    width: $vertical-menu-width;
    z-index: $vertical-menu-z-index;
    border-right: 1px solid $vertical-menu-border;
    bottom: 0;
    margin-top: 0;
    position: fixed;
    top: 0;
    transition: all .1s ease-out;
    flex: 1;

    .navbar-nav {
        .nav-link {
            display: flex;
            align-items: center;
            padding: $vertical-menu-item-padding-y $vertical-menu-item-padding-x;
            color: $vertical-menu-item-color;
            font-size: $vertical-menu-item-font-size;
            font-family: $vertical-menu-item-font-family;

            &.active {
                color: $vertical-menu-item-active-color;
            }

            &:hover {
                color: $vertical-menu-item-hover-color;
            }

            i {
                display: inline-block;
                min-width: $vertical-menu-item-icon-width;
                font-size: 18px;
                line-height: inherit;
            }

            svg {
                width: 18px;
                margin-right: 0.665rem;
                color: $vertical-menu-item-color;
            }

            .badge {
                margin-left: auto;
                margin-right: -2px;
                z-index: 1;
            }

            &:hover {
                color: $vertical-menu-item-hover-color;

                .icon-dual {
                    color: $vertical-menu-item-hover-color;
                    fill: rgba($primary, 0.16);
                }
            }

            &[data-bs-toggle=collapse] {
                &:after {
                    display: block;
                    content: "\F0142";
                    font-family: 'Material Design Icons';
                    margin-left: auto;
                    transition: transform .2s;
                    font-size: 1.05rem;
                    position: absolute;
                    right: 18px;
                    color: $vertical-menu-title-color;
                }

                &[aria-expanded=true] {
                    color: $vertical-menu-item-active-color;

                    .icon-dual {
                        color: $vertical-menu-item-hover-color;
                        fill: rgba($primary, 0.16);
                    }

                    &:after {
                        transform: rotate(90deg);
                        color: $vertical-menu-item-active-color;
                    }

                    &:before {
                        opacity: 1;
                        background-color: $vertical-menu-item-active-color;
                    }
                }
            }
            &.collapsed {
                &.active {
                    &::after {
                        transform: rotate(90deg);
                    }
                }
            }
        }

        .nav-sm {
            padding-left: $vertical-menu-item-icon-width;

            .nav-link {
                padding: $vertical-menu-sub-item-padding-y $vertical-menu-sub-item-padding-x !important;
                color: $vertical-menu-sub-item-color;
                white-space: none;
                position: relative;
                font-size: $vertical-menu-sub-item-font-size;
                font-family: $vertical-menu-sub-item-font-family;

                &:before {
                    content: '';
                    width: 6px;
                    height: 1.5px;
                    background-color: $vertical-menu-sub-item-color;
                    position: absolute;
                    left: 2px;
                    top: 16.5px;
                    transition: all .4s ease-in-out;
                    opacity: 0.5;
                }

                &:hover {
                    color: $vertical-menu-sub-item-hover-color;

                    &:before {
                        background-color: $vertical-menu-sub-item-hover-color !important;
                        opacity: 1;
                    }
                }

                &.active {
                    color: $vertical-menu-item-active-color;

                    &:before {
                        background-color: $vertical-menu-item-active-color;
                    }
                }
            }

            .nav-sm {
                padding-left: 15px;

                .nav-link {

                    &:before {
                        height: 5px;
                        width: 5px;
                        left: 5px;
                        border-radius: 50%;
                        background-color: transparent;
                        border: 1px solid;
                        top: 16px;
                    }
                }
            }
        }
    }

    .btn-vertical-sm-hover {
        color: $text-muted;
        display: none;
    }
}

.navbar-brand-box {
    padding: 0 1.3rem;
    text-align: center;
    transition: all .1s ease-out;

    @media (max-width: 767.98px) {
        display: none;
    }
}

.hamburger-icon {
    width: 20px;
    height: 14px;
    position: relative;
    cursor: pointer;
    display: inline-block;

    span {
        background-color: $text-muted;
        position: absolute;
        border-radius: 2px;
        transition: .3s cubic-bezier(.8, .5, .2, 1.4);
        width: 100%;
        height: 2px;
        display: block;
        left: 0px;

        &:nth-child(1) {
            top: 0;
            width: 80%;
        }

        &:nth-child(2) {
            top: 6px;
        }

        &:nth-child(3) {
            bottom: 0;
            width: 60%;
        }
    }

    .vertical-menu-btn:hover &:not(.open) {
        span {

            &:nth-child(1) {
                top: -1px;
            }

            &:nth-child(3) {
                bottom: -1px;
            }
        }
    }

    &.open {
        transform: rotate(-90deg);

        span {

            &:nth-child(1) {
                left: 1px;
                top: 5px;
                width: 20px;
                transform: rotate(90deg);
                transition-delay: 150ms;
            }

            &:nth-child(2) {
                left: 3px;
                top: 13px;
                width: 10px;
                transform: rotate(45deg);
                transition-delay: 50ms;
            }

            &:nth-child(3) {
                left: 9px;
                top: 13px;
                width: 10px;
                transform: rotate(-45deg);
                transition-delay: 100ms;
            }
        }
    }
}

.logo {
    line-height: $header-height;

    .logo-sm {
        display: none;
    }
}

.logo-light {
    display: none;
}

.menu-title {
    letter-spacing: .05em;
    cursor: default;
    font-size: 11px;
    text-transform: uppercase;
    color: $vertical-menu-title-color;
    font-weight: $font-weight-semibold;

    span {
        padding: 12px 20px;
        display: inline-block;
    }

    i {
        display: none;
    }
}

// rtl
[dir="rtl"] {
    .navbar-menu {
        .navbar-nav {
            .nav-link[data-bs-toggle=collapse] {
                &:after {
                    transform: rotate(180deg);
                }

                &[aria-expanded=true]:after {
                    transform: rotate(270deg);
                }
            }
        }
    }
}