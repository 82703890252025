.react-tel-input  {

    display: flex;
    gap: .2rem;

    .flag-dropdown {
        order: 1; 
    }

    .form-control {
        order: 2; 
        padding-left: .7rem;
    }

    .country-list {
        background-color: var(--#{$variable-prefix}card-bg) !important;
        color: var(--#{$variable-prefix}body-color);
    
        .country {
            background-color: var(--#{$variable-prefix}card-bg) !important;
            color: var(--#{$variable-prefix}body-color);
    
            &:hover {
                filter: brightness(95%);
            }
        }
    
        .country.highlight {
            background-color: var(--#{$variable-prefix}card-bg) !important;
            color: var(--#{$variable-prefix}body-color);
            filter: brightness(120%);
        }
    }

 

    .flag-dropdown, .flag-dropdown.open  {
        position: relative;
        background-color: var(--#{$variable-prefix}card-bg) !important;
        border: 1px solid var(--vz-input-border);

        .selected-flag {
            background-color: var(--#{$variable-prefix}card-bg) !important;

            .flag {
                left: 50%;
                transform: translateX(-50%);
            }
        }
    }
   
}

[data-layout-mode="dark"] {
    .react-tel-input {
        .country-list {
            .country {
                &:hover {
                    filter: brightness(120%);
                }
            }
        }

        .flag-dropdown {
            border-color: rgba(255, 255, 255, 15%);
        }
    }
}
