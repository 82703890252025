.outer-submit-button {
    display: none;
}


@media (max-width: 767px) {
    .outer-submit-button {
        display: block;
    }

    .inner-submit-button {
        display: none;
    }
}