.bootstrap-table {
    height: 1px;

    .selection-column-head, .selection-column-cell {
        height: 100%;
        > div {
            height: 100%;
            display: flex;
            justify-content: center;
        }
    }

    thead {
        tr {
            height: 100%;
            th {
                height: inherit;
                 > div {
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                 }
            }
        }
    }

    .clickable-row {
        cursor: pointer;

        &:hover {
            filter: brightness(60%);
        }
    }
}