.error-page {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    
    .error-container {
        width: max(65%, 300px);
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: white;
        border-radius: 2rem;
        box-shadow: $card-box-shadow;
        overflow: hidden;
        padding: 2rem;

        img {
            width: max(40%, 300px)
        }

        h3 {
            margin-top: 1rem;
            font-size: 1.7rem;
            text-align: center;
        }

        p {
            margin-top: .5rem;
            font-size: 1rem;
            text-align: center;
        }
    }

}