.status-icon {
    
    i {
        font-size: 1.4rem;
        font-weight: bolder;

        &.ri-close-line {
            color: $danger;
        }

        &.ri-check-line {
            color: $success;
        }
    }
}