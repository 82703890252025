// 
// _table.scss
// 

.table {
    th {
        font-weight: $font-weight-semibold;
    }

    >thead {
        border-color: $table-border-color;
    }
    >:not(:first-child) {
        border-top-width: $table-border-width;
    }
}


.table-nowrap {

    th,
    td {
        white-space: nowrap;
    }
}

// card table

.table-card{
    margin: (-$card-spacer-y) (-$card-spacer-x);

    th,
    td {

        &:first-child{
            padding-left: 16px;
        }

        &:last-child{
            padding-right: 16px;
        }
    }

    .table > :not(:first-child) {
        border-top-width: $table-border-width;
    }
}

@each $color,
$value in $theme-colors {
    .border-#{$color} {
        &.table {
            > thead {
                border-color: $value !important;
            }
        }
    }
    .table-#{$color} {
        &.table {
            > thead {
                border-bottom-color:lighten($value, 10%) !important;
            }
        }
    }
}

.table {
    .form-check {
        padding-left: 0px;
        margin-bottom: 0px;
        .form-check-input {
            margin-left: 0px;
            margin-top: 0px;
            float: none;
            vertical-align: middle;
        }
    }
    .sort {
        position: relative;
        &::before {
            content: "\f035d";
            position: absolute;
            right: 0.5rem;
            top: 18px;
            font-size: 0.8rem;
            font-family: "Material Design Icons";
        }
        &::after {
            position:absolute;
            right: 0.5rem;
            content: "\f0360";
            font-family: "Material Design Icons";
            font-size: 0.8rem;
            top: 12px;
        }
    }
}

[data-layout-mode="dark"] {
    .table-light {
        background-color: var(--#{$variable-prefix}light);
        color: var(--#{$variable-prefix}body-color);
        th,td {
            background-color: var(--#{$variable-prefix}light);
        }
    }
}

.order-details-control {
    .rdt_Table {
        .rdt_TableBody {
            max-height: none;
            overflow: visible;
        }
    }    
}

.rdt_Table {

    background-color: var(--#{$variable-prefix}card-bg) !important;
    color: var(--#{$variable-prefix}body-color);

    .sc-hLBbgP {
        padding: 0 5px;
    }

    .rdt_TableHead {

        .rdt_TableHeadRow  {
            background-color: var( --#{$prefix}table-header-bg) !important;
            color: white;
            border-bottom-color: var(--#{$variable-prefix}border-color);

            .form-select, .form-control {
                font-size: 13px;
            }

            .form-select[data-filtered="true"], .form-control[data-filtered="true"] {
                border: 1px solid rgba(233, 229, 0, .7) !important;
            }

            [data-column-id="food-id-column"] {
                min-width: 30px !important;
            }
        }
    }

    .sc-iveFHk  {
        background-color: var(--#{$variable-prefix}card-bg) !important;
        color: var(--#{$variable-prefix}body-color);
    }

    .rdt_TableBody {

        max-height: 50vh;
        overflow-y: auto;

        
        &::-webkit-scrollbar {
            -webkit-appearance: none;
        }

        &::-webkit-scrollbar:vertical {
            width: 8px;
        }

        &::-webkit-scrollbar:horizontal {
            height: 10px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: rgba(var(--#{$prefix}dark-rgb), .09);
            border-radius: 8px;
        }

        &::-webkit-scrollbar-track {
            border-radius: 8px;
        }
        
        .rdt_TableRow {
            background-color: var(--#{$variable-prefix}card-bg);
            color: var(--#{$variable-prefix}body-color);

            &:not(:last-of-type) {
                 border-bottom-color: var(--#{$variable-prefix}border-color);
            }

            :is(.sc-iBYQkv, .eiPgcm) svg {
                color: var(--#{$variable-prefix}body-color);
            }

            [data-column-id="food-id-column"] {
                min-width: 30px !important;
            }

            [data-column-id="food-image-column"] {
                display: flex;
                justify-content: center;
            }
        }

        .rdt_TableRow:nth-child(even) {
            background-color: var(--#{$prefix}table-even-row-bg);
        }

    }
}


.no-data-component, .progress-component {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1.6rem;
    background-color: var(--#{$variable-prefix}card-bg) !important;
    color: var(--#{$variable-prefix}body-color);
}


.rdt_Pagination {

    background-color: var(--#{$variable-prefix}card-bg) !important;
    color: var(--#{$variable-prefix}body-color) !important;
    border-top-color: var(--#{$variable-prefix}border-color) !important;

    button {
        fill: var(--#{$variable-prefix}dark);
    }

    button:disabled {
        fill: rgba(var(--#{$variable-prefix}dark-rgb), 0.25);
    }
}

[data-layout-mode="light"] {
    .rdt_Table {
        .rdt_TableHead {
            .rdt_TableHeadRow  {

                .form-control, .form-select {
                    color: white;
                    background-color: #7A86B6;
                    border-color: var( --#{$prefix}table-header-bg) !important;
                }

                .form-select[data-filtered="true"], .form-control[data-filtered="true"] {
                    border: 1px solid rgb(86, 255, 218) !important;
                }

                .form-control::placeholder {
                    color: white;
                }
            }
        }
    }
}

.homepage-table-row {
    cursor: pointer;

    &:hover {
        background-color: rgb(245, 245, 245);
    }

  }
  
[data-layout-mode="dark"] .homepage-table-row:hover {
    background-color: rgb(46, 46, 46);
}