// 
// toastify.scss
// 


.Toastify {
    .Toastify__toast-body {
        font-family: $font-family-base;
    }
}


.toast-close {
    opacity: 0.4;
    padding: 0 5px;
    position: relative;
    left: 4px;
    margin-left: 4px;
    border: none;
    background: none;
}

.toastify-right {
    right: 15px;
}

.toastify-left {
    left: 15px;
    .toast-close {
        left: -4px;
        margin-left: 0;
        margin-right: 4px;
    }
}

.toastify-top {
    top: -150px;
}

.toastify-bottom {
    bottom: -150px;
}

.toastify-rounded {
    border-radius: 25px;
}

.toastify-avatar {
    width: 1.5em;
    height: 1.5em;
    margin: -7px 5px;
    border-radius: 2px;
}

.toastify-center {
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    max-width: fit-content;
    max-width: -moz-fit-content;
}

@media only screen and (max-width: 360px) {
    .toastify-right, .toastify-left {
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        max-width: fit-content;
    }
}