.file-preview-container {
    width: 100%;
    max-width: 400px;
    height: 170px;
    position: relative;
    overflow: hidden;
    border-radius: .5rem;
    background-color: #222;

    &.loading {
        filter: brightness(30%);
        pointer-events: none;
    }

    .loading-spinner {
        position: absolute;
        top: 46%;
        left: 46%;
    }

    .file-title-container {
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
        isolation: isolate;

        .faded-overlay {
            position: absolute;
            top: -5px;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: -1;
            background: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, .65));
            filter: blur(7px);
            opacity: 0.76;
        }

        .file-info-container {
            width: 100%;
            display: flex;
            align-items: center;
            gap: .4rem;
            color: white;
            z-index: 10;
            padding: .6rem .7rem;

            .remove-file {
                padding: .3rem;
                border: 2px solid;
                border-color: transparent;
                border-radius: 50%;
                width: 30px;
                height: 30px;
                display: flex;
                justify-content: center;
                align-items: center;
                transition: .3s ease;
                cursor: pointer;
    
                &:hover {
                    border-color: white;
                }
    
                i {
                    font-size: 1rem;
                    font-weight: 900;
                }
            }
    
            .file-title {
                font-size: .85rem;
            }
        }
    }

    .file-preview {
        
        height: 100%;
        width: 100%;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}