.draggable-category-card {
    overflow: hidden;
    border: 1px dashed rgba(0, 0, 0, .2);

    .category-header-container {
        background-color: #3d038f;
        display: flex;
        align-items: center;
        gap: 1rem;
        font-size: 1rem;
        color: white;

        i {
            font-size: 1rem;
        }

        .expand-arrow {
            margin-left: auto;
        }
    }

    .category-content-wrapper {
        transition: .4s ease;

        .category-items-container {

            .category-item {
            }
        }
    }
    
}