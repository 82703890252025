.image-input-container {
    width: 80px;
    height: 80px;
    border: 1px rgb(182, 182, 182) dashed;
    border-radius: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    aspect-ratio: 1;
    position: relative;

    &.has-image {
        border: none;
    }

    &.loading {

        .loading-indicator {
            display: block;
        }

        .placeholder-container, .image-display-container, .remove-image-button, .status-icon {
            filter: brightness(60%);
            cursor: auto;
            pointer-events: none;
        }
    }

    .placeholder-container {
        position: relative;
        cursor: pointer;

        i {
            position: relative;
            font-size: 3rem;
            color: rgb(182, 182, 182);
        }

        .add-image-button {
            position: absolute;
            bottom: 10px;
            right: -5px;
            font-size: 1rem;
            width: 20px;
            height: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            background: green;
            color: white;
        }
    }

    .image-display-container {
        height: 100%;
        width: 100%;
        position: relative;
        overflow: hidden;
        border-radius: inherit;

        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }

            
    .remove-image-button {
        position: absolute;
        top: -5px;
        right: -10px;
        font-size: 1rem;
        width: 20px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        background: red;
        color: white;
        cursor: pointer;
    }

    .status-icon {
        position: absolute;
        top: -9px;
        left: -5px;
        filter: drop-shadow(1px 1px 3px rgba(0, 0, 0, .6));
    }

    .loading-indicator {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 1.2rem;
        display: none;
        z-index: 2;
    }


}